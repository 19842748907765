import Vue from "vue"
import Router from 'vue-router';

const Home = () => import(/* webpackChunkName: "home" */ '../views/home/index')

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: '首页' }
    }
  ]
})

Vue.use(Router)
export default router
import api from "@/api";

export default {
  namespaced: true,
  state: {
    unionInfo: null,
  },
  mutations: {
    setData(state, payload) {
      state[payload.key] = payload.value;
    },
  },
  actions: {
    /** 查询机构信息 */
    async handlerGetUnionInfo({ commit, state }, payload = {}) {
      let unionInfo = state.unionInfo || window.core233.utils.cacheGet("unioninfo_from_cache", "local");
      if (!unionInfo) {
        const { data = {} } = await api.getUnionInfo(payload);
        unionInfo = data;
        window.core233.utils.cacheSet("unioninfo_from_cache", unionInfo, 5, "local");
      }
      commit("setData", {
        key: "unionInfo",
        value: unionInfo,
      });
    },
  },
  getters: {
    // 是否显示课程价格
    isShowPrice(s) {
      return !s.unionInfo?.unionPermissions?.isHideStudentPrice;
    },
  },
};

import Cookies from "js-cookie"

/**
 *
 * @returns 获取javatoken
 */
 export function getJaveToken() {
    return Cookies.get('h5loginclientauthentication') || Cookies.get('clientauthentication') || localStorage.getItem('javatoken') || '';
}

//生成Sid
export const createSid = () => {
    const d = new Date();
    const add0 = (num) => (num < 10 ? '0' + num : num);
    const time = [d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()].map((i) => add0(i)).join('');
    const r = parseInt(Math.random() * 89999 + 10000);
    const rr = parseInt(Math.random() * 89999 + 10000);
    return 'businessadmin' + time + r + rr;
};

// 生成params
/**
 * filterNull 是否过滤空串
 */
export function createParams(method, data, url, filterNull = true) {
    let params = '';
    switch (method) {
        case 'post':
            if (filterNull) {
                for (let key in data) {
                    if (data[key] === null || data[key] === 'null' || data[key] === 'undefined') {
                        delete data[key];
                    } else {
                        if (typeof data[key] === 'string') {
                            // post方法去掉前后空格
                            data[key] = data[key].trim();
                        }
                    }
                }
            }
            params = data ? JSON.stringify(data) : '';
            break;
        case 'get':
            if (url.indexOf('?') > -1) {
                let midObj = strToObj(url.slice(url.indexOf('?') + 1, url.length).trim());
                for (let k in midObj) {
                    if (midObj[k] === '' || midObj[k] === 'null' || midObj[k] === 'undefined') {
                        delete midObj[k];
                    }
                }
                params = objToStr(midObj).trim();
                url = url.slice(0, url.indexOf('?') + 1) + params;
            } else params = '';
            break;
        default:
            params = url.indexOf('?') > -1 ? url.slice(url.indexOf('?') + 1, url.length).trim() : '';
    }
    return { url, params };
}

/**
 * &隔开的字符串转成对象
 */
export function strToObj(str) {
    let arr = str.split('&');
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=');
        let val = arr2[1];
        switch (arr2[0]) {
            case 'userName':
            case 'nickName':
            case 'bossName':
                val = decodeURI(arr2[1]);
                break;
        }
        obj[arr2[0]] = val;
    }
    return obj;
}

/**
 * 对象转成&隔开
 */
export function objToStr(data) {
    let keys = Object.keys(data);
    let arr = [];
    keys.forEach((x) => {
        let val = data[x];
        switch (x) {
            case 'userName':
            case 'nickName':
            case 'bossName':
                val = encodeURI(val);
                break;
        }
        arr.push(x + '=' + val);
    });
    return arr.join('&');
}
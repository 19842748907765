import axios from 'axios'
import qs from 'qs'
const isDev = process.env.VUE_APP_MODE === 'test'

// export var host = window.location.host;
// export var host = "ks.rrxue.cn"
export var host = "gj.rrxue.cn"
// host = "wx.hdgk.cn"
// host = "wx.029985.com"

axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem("usertoken")
  if (token == undefined) {
    token = ""
    if (process.env.NODE_ENV === 'development') {
      // token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjE3MTY2NDQ4LCJ1c2VybmFtZSI6InJyeHVlIiwidW5pb25pZCI6IjI3Mjk0IiwiaXNzdWVyIjoidXNlciIsImF1ZGllbmNlIjoiIn0.v1Rlxcz99uv2D-Yddp00iJ329gv-A6V6HLNGiApUThM"
      token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjE0NjQ5NzEyLCJ1c2VybmFtZSI6Ijk2NjQ2MDZhIiwidW5pb25pZCI6IjUzNjAzIiwiaXNzdWVyIjoidXNlciIsImF1ZGllbmNlIjoiIn0.kZYaOGayYhOWg-wGv16BavziWVvaKTKje129s-89Kmg"
      // token = ""
    }
  }
  config.params = {
    stamp: new Date().getTime(),
    token,
    host: window.location.host,
    ...config.params
  }
  if (process.env.NODE_ENV === "development") {
    config.params.host = host;
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// export const uri = isDev ? 'http://192.168.0.164' : 'https://uapi.233.com'
export const uri = isDev ? 'https://uapi.233.com' : 'https://uapi.233.com'
// export const uri = isDev ? 'https://uapi.233.com' : 'https://uapi.233.com'


export const GetCookies = (params, callback) => get('/unioninfo/promote/GetCookies', params, callback)// 获取cookies
// export const GetUserInfo = (params, callback) => get('/Member/Info/GetUserInfo', params, callback)// 导航用户信息
// export const GetConfig = (params, callback) => get('/UnionInfo/Info/GetConfig', params, callback)// 导航
export const GetSeniorFlag = (params, callback) => get('/unioninfo/info/GetSeniorFlag', params, callback)// 获取是否高级平台
export const GetLoginStatus = (params, callback) => get('/unioninfo/info/GetLoginStatus', params, callback)// 获取登录状态
export const GetUnionLogoByHost = (params, callback) => get('/unioninfo/info/GetUnionLogoByHost', params, callback)// 获取logo
export const GetPhoneByHost = (params, callback) => get('/unioninfo/info/GetPhoneByHost', params, callback)// 获取顶部电话

export const GetHeaderByHost = (params, callback) => get('/unioninfo/info/GetHeaderByHost', params, callback)// links
export const GetBannerByHost = (params, callback) => get('/unioninfo/info/GetBannerByHost', params, callback)// banner
export const GetOrderCountAndCouponCount = (params, callback) => get('/unioninfo/info/GetOrderCountAndCouponCount', params, callback)// num

export const GetUnionClass = (params, callback) => get('/course/class/GetUnionClass', params, callback)// 联盟考试
export const GetHomePageCourseByClassID = (params, callback) => get('/course/course/GetHomePageCourseByClassID', params, callback)// 课程推荐
export const GetExaminationCountDown = (params, callback) => get('/Course/Category/GetExaminationCountDown', params, callback)// 获取考试倒计时
export const GetClassTopThreeByHost = (params, callback) => get('/Course/Class/GetClassTopThreeByHost', params, callback)// 热门课程
export const GetAppQrcode = (params, callback) => get('/unioninfo/info/GetAppQrcode', params, callback)// 二维码
export const getlive = (params, callback) => get('/live/live/getlive', params, callback)// 直播
export const GetHomePageGetCouponByHost = (params, callback) => get('/unioninfo/info/GetHomePageGetCouponByHost', params, callback)// 隔断处理 1有优惠券未登录 2有优惠券登录 3开通扫码领课且没有优惠券 4 隐藏
export const GetTeacherListByHost = (params, callback) => get('/unioninfo/info/GetTeacherListByHost', params, callback)// 网校老师

export const GetSkinColorByHost = (params, callback) => get('/unioninfo/info/GetSkinColorByHost', params, callback)// 换肤
export const GetKeFuByHost = (params, callback) => get('/unioninfo/info/GetKeFuByHost', params, callback)// 客服

export const GetFooterByHost = (params, callback) => get('/unioninfo/info/GetFooterByHost', params, callback)// 获取页尾
export const Statistics = (params, callback) => get('/UnionInfo/Info/Statistics', params, callback)// cnzz

export const GetUnionPermission = (params, callback) => get('/unioninfo/info/GetUnionPermission', params, callback)// 隐藏优惠券

export const get = (api, params, callback) => axios.get(uri + api, {
  params: params
}).then(res => {
  callback(res.data)
})

export const post = (api, params, callback) => axios.post(uri + api, qs.stringify(params))
  .then(res => {
    callback(res.data)
  })

<template>
  <router-view></router-view>
</template>

<script>
import Cookies from "js-cookie";
import { GetCookies } from "./lib/request";
import { Statistics } from "@/lib/request";
export default {
  name: "App",
  mounted() {
    this.Statistics();

    if (!Cookies.get("PromoteInfo")) {
      const _location_params = window.location.search.split("?")[1];
      let _promotesign = "";
      _location_params &&
        _location_params.split("&").forEach((item) => {
          item.indexOf("promotesign") > -1 &&
            (_promotesign = item.slice(
              item.indexOf("promotesign") + 12,
              item.length
            ));
        });
      _promotesign &&
        GetCookies({ promotesign: _promotesign }, (res) => {
          const { Result, ResultDescription } = res;
          if (!ResultDescription) {
            const { StaffName, PromoteId } = Result;
            StaffName &&
              PromoteId &&
              Cookies.set(
                "PromoteInfo",
                `StaffName=${StaffName}&PromoteId=${PromoteId}`,
                { expires: 3 }
              );
          }
        });
    }
  },
  methods: {
    Statistics() {
      Statistics({}, (res) => {
        const { Result } = res;
        window.setUnionCnzzConfig(Result);
        // 设置cnzz
        // this.cnzzKey = Result.Key
        // const _script1 = document.createElement("script")
        // _script1.type = "text/javascript"
        // _script1.src = "https://v1.cnzz.com/z_stat.php?id=" + Result.Key + "&online=1&show=line"
        // document.body.appendChild(_script1)
      });
      const _script2 = document.createElement("script");
      _script2.type = "text/javascript";
      _script2.src =
        "https://s9.cnzz.com/z_stat.php?id=1278181871&web_id=1278181871";
      document.body.appendChild(_script2);
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft Yahei", "苹方", Helvetica, sans-serif;
}
body {
  background: #f3f4f5;
}
</style>

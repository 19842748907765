import axios from 'axios';
import md5 from 'js-md5';
import { createSid, createParams, getJaveToken } from './index';
import { host } from '@/lib/request';

const service = axios.create({
    baseURL: 'https://japi.233.com',
});

// java请求封装
service.interceptors.request.use(
    (config) => {
        const { method, data, url } = config;
        // 是否过滤空串
        const filterNull = config.headers['nfilterNull'] ? false : true;
        const { params, url: nurl } = createParams(method, data, url, filterNull);

        const sid = createSid();
        config.url = nurl;
        config.headers['sid'] = sid;

        let sign = '';
        if (Object.prototype.toString.call(data) == '[object FormData]') sign = md5('RZRRNN9RXYCP' + sid).toUpperCase();
        else if (method == 'get') sign = md5('RZRRNN9RXYCP' + sid + decodeURIComponent(params)).toUpperCase();
        else sign = md5('RZRRNN9RXYCP' + sid + params).toUpperCase();

        config.headers['sign'] = sign;
        config.headers['token'] = getJaveToken();
        config.headers['unioncode'] = process.env.NODE_ENV === 'development' ? host : window.location.host;

        return config;
    },
    (error) => Promise.reject(error),
);

// 错误处理
service.interceptors.response.use(
    (res) => {
        const { data, config } = res;
        if (config.headers.hideErr) {
            return Promise.resolve(data);
        }
        if (res.data) {
            let vm = res.data;

            if (vm.status) {
                return Promise.resolve(vm);
            } else if (vm.code === 401) {
                // Message.error(vm.message);
                // store.dispatch('user/logout', {})
                // router.push("/login");
                localStorage.removeItem('usertoken');
                return Promise.reject(vm);
            } else {
                // Message.error(vm.message);
                return Promise.reject(vm);
            }
        }
    },
    (err) => {
        // let status_code = err.response && err.response.status ? err.response.status + '' : 0;
        // if (status_code == 500) Message.error("服务器内部错误,请联系管理员");
        // else {
        //   if (status_code) Message.error(status_code);
        // }

        return Promise.reject(err.response);
    },
);

export default service;
